export const getAppRootUrl = () => {
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
  if (isProduction) {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL}`;
  }

  const previewDomainName = process.env.NEXT_PUBLIC_VERCEL_URL;
  if (previewDomainName) {
    return `https://${previewDomainName}`;
  }

  return 'http://localhost:3000';
};
