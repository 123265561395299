'use client';

import { Auth0Provider } from '@auth0/auth0-react';
import { getAppRootUrl } from '@lib/get-app-root-url';
import { Theme } from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';
import { APIClient } from '@repo/api-client';
import {
  AnalyticsProvider,
  useAnalyticsStoreActions,
} from '@repo/frontend-shared/analytics';
import { MyUserProvider } from '@repo/frontend-shared/contexts';
import { CheckFillIcon } from '@repo/ui/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';
import Head from 'next/head';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import './globals.css';

// NOTE: api-client should be configured on app start, to be sure to use baseURL
// It's a singleton because it's used everywhere in the app, even in shared packages.
// Another viable pattern could be to instanciate an API Client here (w/o singleton) and share it accross the component via a Context
APIClient.client.configure({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL || '',
});

const AnalyticsPageView = dynamic(
  // TODO: update this
  () => import('@contexts/analytics/TrackPageView'),
  {
    ssr: false,
  },
);

const inter = Inter({ subsets: ['latin'], variable: '--font-inter' });

const AUTH_REDIRECT_URL = `${getAppRootUrl()}/`;

const queryClient = new QueryClient();

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  useEffect(() => {
    document.title = 'SPX'; // https://github.com/vercel/next.js/discussions/50872
  }, []);

  const { setSessionId } = useAnalyticsStoreActions();

  return (
    <html
      lang="en"
      suppressHydrationWarning={true} // thanks to https://stackoverflow.com/a/75339011/18364469
    >
      <Head>
        <link rel="icon" href="./icon.ico" sizes="any" />
      </Head>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
        authorizationParams={{
          redirect_uri: AUTH_REDIRECT_URL,
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <MyUserProvider>
            <AnalyticsProvider
              onSessionIdChange={setSessionId}
              posthogConfig={{
                host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
                key: process.env.NEXT_PUBLIC_POSTHOG_KEY,
                debugMode:
                  process.env.NEXT_PUBLIC_POSTHOG_DEBUG_MODE === 'true',
              }}
              datadogConfig={{
                isEnabled: process.env.NEXT_PUBLIC_ENABLE_DATADOG === 'true',
                applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
                clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
                env: process.env.NEXT_PUBLIC_ENV,
                appName: 'frontend',
                appVersion: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
              }}
            >
              <body
                className={classNames(
                  inter.className,
                  'h-screen overflow-y-scroll bg-white',
                )}
              >
                <AnalyticsPageView />
                <Theme
                  accentColor="jade"
                  grayColor="slate"
                  panelBackground="solid"
                  scaling="100%"
                  radius="medium"
                  className="h-full w-full"
                >
                  <div id="portal-root-container" className="h-full w-full">
                    {children}
                    <Toaster
                      position="bottom-center"
                      toastOptions={{
                        success: {
                          className: '!bg-gray11 !text-white !p-3',
                          icon: <CheckFillIcon className="h-6 w-6" />,
                        },
                      }}
                    />
                  </div>
                </Theme>
                <ReactQueryDevtools initialIsOpen={false} />
              </body>
            </AnalyticsProvider>
          </MyUserProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </html>
  );
}
