import { APIClient } from '@repo/api-client';
import { FunctionComponentWithChildren } from '@repo/frontend-shared/custom-types';
import { useSpxApiQuery } from '@repo/frontend-shared/spx-api';
import { IMyUserResponseDto } from '@repo/interfaces';
import { createContext, useContext, useEffect, useState } from 'react';

type MyUser = IMyUserResponseDto;

type MyUserContextValue = {
  myUser: MyUser | null;
};

const MyUserContext = createContext<MyUserContextValue>({ myUser: null });

export const useMyUserContext = (): MyUserContextValue => {
  const context = useContext(MyUserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const MyUserProvider: FunctionComponentWithChildren = ({ children }) => {
  const [myUser, setMyUser] = useState<MyUser | null>(null);

  const query = useSpxApiQuery({
    queryKey: ['me'],
    queryFn: () => APIClient.client.getMyUser(),
  });

  useEffect(() => {
    if (query.data) {
      setMyUser(query.data);
    }
  }, [query.data]);

  return (
    <MyUserContext.Provider value={{ myUser }}>
      {children}
    </MyUserContext.Provider>
  );
};
